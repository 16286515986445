import { useEffect, useState } from "react"

const Form = () => {
    const [paramsAdded, setParamsAdded] = useState(false);
    const [paramsString, setParamsString] = useState("");

    useEffect(() => {
        const fetchParams = async () => {
            try {
                
                const urlParams = new URLSearchParams(window.location.search)
                const token = urlParams.get('token')
                const submissionID = urlParams.get('submissionID')
                
                fetch(`/api/getPreviousSubmission`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    body: JSON.stringify({ submissionID })
                }).then((response) => response.json()).then(async (data) => {
                    const queryObj = data.data.queryObj;
        
                    let queryStr = window.location.search;
                    for (let key in queryObj) {
                        queryStr += `&${key}=${queryObj[key]}`
                    }

                    console.log(queryStr)

                    setParamsString(queryStr)
        
                    setParamsAdded(true)
                }).catch((error) => {
                    console.error(error)
                })
            } catch (error) {
                console.error(error)
            }
        }

        fetchParams()
    }, [])

    /*useEffect(() => {
        const fetchHtmlContent = async () => {
            try {
                fetch(`/jotform.html`).then((response) => response.text()).then((html) => {
                    setHTMLContent(html)
                })
            } catch (error) {
                console.error(error)
            }
        }

        if (paramsAdded) {
            fetchHtmlContent()
        }
    }, [paramsAdded])*/

    if (!paramsAdded) {
        return (
            <div>
                <h1>Loading...</h1>
            </div>
        )
    }

    return (
        <iframe src={`/jotform.html${paramsString}`} style={{ width: '100%', height: '100vh', border: 'none' }}></iframe>
    )

    
}

export default Form